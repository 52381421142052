@import url('material-design-icons/iconfont/material-icons.css');

body {
  margin: 0;
  background-color: #FAFAFF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Fonts*/
/*Archia*/
@font-face {
  font-family: 'Open Sans';
  src: url('static/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('static/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans ExtraBold';
  src: url('static/assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('static/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('static/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Muli*/
@font-face {
  font-family: 'Muli';
  src: url('static/assets/fonts/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli Bold';
  src: url('static/assets/fonts/Muli-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli Black';
  src: url('static/assets/fonts/Muli-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli ExtraBold';
  src: url('static/assets/fonts/Muli-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli Light';
  src: url('static/assets/fonts/Muli-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli SemiBold';
  src: url('static/assets/fonts/Muli-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat';
  src: url('static/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Montserrat Bold';
  src: url('static/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Container for media queries*/

.container-habi {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 414px) {
  .container-habi {
    max-width: 736px;
  }
}

@media screen and (min-width: 768px) {
  .container-habi {
    max-width: 992px;
  }
}

@media screen and (min-width: 1024px) {
  .container-habi {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1280px) {
  .container-habi {
    max-width: 1280px;
  }
}
